import { useEffect } from "react";

const useParallax = (ref, speed = 0.07) => {
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const yPos = -window.scrollY * speed;
        ref.current.style.backgroundPosition = `center ${yPos}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref, speed]);
};

export default useParallax;
