import React, { useRef, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";
import DataContext from "../contexts/DataContext";
import { HashLink } from "react-router-hash-link";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useParallax from "../hooks/useParallax";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [content, setContent] = useState("");
  const [delta, setDelta] = useState(150 - Math.random() * 50);
  const period = 1000;

  const { data } = useContext(DataContext);
  const generalInfo = data?.general_info || {};
  const heroSection = data?.hero_section
    ? Object.values(data.hero_section)
    : [];
  const bannerRef = useRef(null);
  const confSpeedHeroSection =
    parseInt(generalInfo.conf_speed_hero_section, 10) || 5000;

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: confSpeedHeroSection,
    arrows: false,
  };

  useParallax(bannerRef); // Use the custom parallax hook

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <section
      className="banner"
      id="home"
      ref={bannerRef}
      style={{ paddingBottom: "200px" }}
    >
      <Container>
        <Slider {...settings}>
          {heroSection.map((section, index) => (
            <div key={index}>
              <Row className="align-items-center">
                <Col xs={12} md={7} xl={7}>
                  <div className="animate__animated animate__fadeIn">
                    <h1>{section.title}</h1>
                    <p>{section.content}</p>
                  </div>
                </Col>
                <Col xs={12} md={5} xl={5}>
                  <div className="animate__animated animate__zoomIn">
                    <img
                      src={section.image || headerImg}
                      alt="Header Img"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  );
};
