import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { ProjectDetails } from "./components/ProjectDetails";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { DataProvider, DataContext } from "./contexts/DataContext";
import LoadingSpinner from "./components/LoadingSpinner";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./components/ScrollToTop";

const MainContent = () => {
  const { data, apiKey, loading, errorUser } = useContext(DataContext);

  if (loading) {
    return <LoadingSpinner />;
  }
  // console.log("data", data);
  // console.log("apiKey", apiKey);
  // console.log("loading", loading);
  // console.log("errorUser", errorUser);

  // if (errorUser || errorUser === undefined) {
  //   return <div>User doesn't exist</div>; // Display the error message
  // }

  if (loading || !data) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Banner />
      <Skills />
      <Projects />
      <Contact apiKey={apiKey} />
    </>
  );
};

const AppContent = () => {
  const { userId } = useParams();
  const { data, loading, errorUser } = useContext(DataContext);
  const location = useLocation();

  // console.log("data::", data);
  // console.log("loading::", loading);
  // console.log("errorUser::", errorUser);

  useEffect(() => {
    if (data) {
      document.title = data.general_info.my_title || "My Portfolio";
    }
  }, [data, location]);

  if ((loading || !data) && !errorUser) {
    return <LoadingSpinner />;
  }

  // if (errorUser) {
  //   return <div>User doesn't exist</div>; // Display the error message
  // }

  if (errorUser) {
    return <div></div>; // Display the error message
  }

  return (
    <div className="App">
      <NavBar userId={userId} />
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/projects/:idk/:slug?" element={<ProjectDetails />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

const WrappedAppContent = () => {
  const { userId } = useParams();
  return (
    <DataProvider userId={userId}>
      <AppContent />
    </DataProvider>
  );
};

function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/:userId/*" element={<WrappedAppContent />} />
      </Routes>
    </Router>
  );
}

export default App;
