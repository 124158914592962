import React from "react";
import spinner from "../assets/img/spinner.gif"; // Make sure to add a spinner.gif in the appropriate directory

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-overlay">
      <img src={spinner} alt="Loading..." className="loading-spinner" />
    </div>
  );
};

export default LoadingSpinner;
