import React, {
  useEffect,
  useLayoutEffect,
  useContext,
  useState,
  useRef,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../contexts/DataContext";
import LoadingSpinner from "./LoadingSpinner";
import { Parallax } from "react-parallax";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
import ScrollToTop from "./ScrollToTop";
import "react-image-gallery/styles/css/image-gallery.css";

export const ProjectDetails = () => {
  const { idk } = useParams();
  const { projectDetails, getProjectDetails, loading, data } =
    useContext(DataContext);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const previousIdkRef = useRef();
  const [loadingContent, setLoadingContent] = useState(true);
  const sidebarRef = useRef(null);
  const stickyDivTop = useRef(0);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (idk && idk !== previousIdkRef.current) {
        await getProjectDetails(idk);
        previousIdkRef.current = idk;
        // setInitCounter((prevCounter) => prevCounter + 1);
        setLoadingContent(false);
      }
    };

    fetchProjectDetails();
  }, [idk, getProjectDetails]);

  useEffect(() => {
    if (projectDetails && projectDetails.chapters.length > 0) {
      setSelectedChapter(projectDetails.chapters[0]);
      // setInitCounter((prevCounter) => prevCounter + 1);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (projectDetails && data) {
      document.title = `${data.general_info.my_title} - ${projectDetails.title}`;
    }
  }, [projectDetails, data]);

  const handleScroll = () => {
    const image = document.querySelector(".project-image");
    if (image) {
      const speed = 0.5; // Change speed to control parallax effect
      const yPos = -window.scrollY * speed;
      image.style.transform = `translateY(${yPos}px)`;
    }

    const sidebar = sidebarRef.current;
    const chapterContent = document.querySelector(".chapter-content");
    const offset = 75; // Adjust the value based on your header height

    if (!chapterContent) return;

    const chapterContentRect = chapterContent.getBoundingClientRect();
    // const sidebarHeight = sidebar.offsetHeight;
    const scrollY = window.scrollY || window.pageYOffset;
    const scrollBottom = scrollY + window.innerHeight;
    const chapterBottom = chapterContentRect.bottom + scrollY;
    // const chapterHeight = chapterContent.offsetHeight;
    const scrollPercent = (scrollBottom / chapterBottom) * 100;
    const windowWidth = window.innerWidth;

    if (windowWidth > 768) {
      // Apply sticky sidebar logic only for larger screens
      if (sidebar && scrollY > stickyDivTop.current - offset) {
        if (scrollPercent >= 90) {
          // console.log("Applying relative position at 90% of chapter content");
          sidebar.style.position = "relative";
          sidebar.style.top = "initial";
          if (chapterContent) {
            chapterContent.style.marginLeft = "-20px";
          }
        } else {
          // console.log("Applying fixed position");
          sidebar.style.position = "fixed";
          sidebar.style.top = `${offset}px`;
          if (chapterContent) {
            chapterContent.style.marginLeft = "270px";
          }
        }
      } else if (sidebar) {
        // console.log("Applying relative position");
        sidebar.style.position = "relative";
        sidebar.style.top = "initial";
        if (chapterContent) {
          chapterContent.style.marginLeft = "-20px";
        }
      }
    } else {
      // Ensure relative position for smaller screens
      // console.log("Screen width is less than or equal to 768px, applying relative position");
      sidebar.style.position = "relative";
      sidebar.style.top = "initial";
      chapterContent.style.marginLeft = "0"; // Remove margin for relative position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    const calculateStickyDivTop = () => {
      if (sidebarRef.current) {
        stickyDivTop.current =
          sidebarRef.current.getBoundingClientRect().top + window.pageYOffset;
        // console.log("Calculated stickyDivTop.current:", stickyDivTop.current);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", calculateStickyDivTop);
    calculateStickyDivTop(); // Initial calculation

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", calculateStickyDivTop);
    };
  }, [sidebarRef.current]);

  if (loadingContent) {
    return <LoadingSpinner />;
  }

  const {
    title = "No title available",
    image,
    skill_type = "N/A",
    skill = "N/A",
    date_reg = "N/A",
    description = "No description available",
    url_git,
    chapters = [],
  } = projectDetails || {};

  const cleanHTMLContent = (htmlContent) => {
    return htmlContent.replace(/<br\s*\/?>/g, "").replace(/\s\s+/g, " ");
  };

  const images =
    selectedChapter?.images?.map((img) => ({
      original: img.file,
      thumbnail: img.file,
      description: img.title,
    })) || [];

  const videos =
    selectedChapter?.videos?.map((video) => ({
      url: video.url,
      title: video.title,
    })) || [];

  const shareUrl = window.location.href;
  // console.log("Images: ", images);
  // console.log("Videos: ", videos);

  return (
    <section className="project-details" id="project-detail">
      <div className="project-details-container" style={{ display: "flex" }}>
        <div className="project-info">
          <Parallax bgImage={image} strength={300}>
            <div style={{ height: 500, position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textAlign: "center",
                  zIndex: 10,
                }}
              >
                <h1 className="project-title">{title}</h1>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 0,
                }}
              ></div>
            </div>
          </Parallax>
          <div className="project-meta">
            <p>
              <strong>Type:</strong> {skill_type}
            </p>
            <p>
              <strong>Skill:</strong> {skill}
            </p>
            {/* <p>
              <strong>Date:</strong> {date_reg}
            </p> */}
          </div>
          <div
            className="project-description"
            dangerouslySetInnerHTML={{
              __html: cleanHTMLContent(description),
            }}
          />
          <div className="project-content" style={{ display: "flex" }}>
            {/* <div className="sidebar"> */}
            <div ref={sidebarRef} className="sidebar">
              <h2>Chapters</h2>
              <ul className="chapter-list">
                {chapters.map((chapter) => (
                  <li
                    key={chapter.id_knowledge_chapter}
                    className={
                      selectedChapter?.id_knowledge_chapter ===
                      chapter.id_knowledge_chapter
                        ? "active"
                        : ""
                    }
                    onClick={() => setSelectedChapter(chapter)}
                  >
                    {chapter.title}
                  </li>
                ))}
              </ul>
            </div>
            <div className="chapter-content">
              {selectedChapter && (
                <>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: cleanHTMLContent(selectedChapter.title),
                    }}
                  />
                  {selectedChapter.image && (
                    <img
                      src={cleanHTMLContent(selectedChapter.image)}
                      alt={cleanHTMLContent(selectedChapter.title)}
                      className="chapter-image"
                    />
                  )}
                  <div
                    className="chapter-content-details"
                    dangerouslySetInnerHTML={{
                      __html: cleanHTMLContent(selectedChapter.content),
                    }}
                  />

                  {/* <div>{selectedChapter.content}</div> */}
                  {/* Display Chapter Images */}
                  {selectedChapter.images?.length > 0 && (
                    <div className="chapter-images">
                      <h3>Images</h3>
                      <div className="image-gallery-container">
                        <ImageGallery items={images} />
                      </div>
                    </div>
                  )}

                  {/* Display Chapter Videos */}
                  {selectedChapter.videos?.length > 0 && (
                    <div className="chapter-videos">
                      <h3>Videos</h3>
                      <div className="video-gallery-container">
                        {videos.map((video, index) => (
                          <div key={index} className="video-wrapper">
                            <ReactPlayer
                              url={video.url}
                              controls
                              width="100%"
                            />
                            <p>{video.title}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* Display Chapter Files */}
                  {selectedChapter.files?.length > 0 && (
                    <div className="chapter-files">
                      <h3>Files</h3>
                      <ul>
                        {selectedChapter.files.map((file) => (
                          <li key={file.id_knowledge_chapter_file}>
                            <a href={file.file} download={file.file_name}>
                              {file.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {/* Display Support URLs */}
                  {selectedChapter.support_urls?.length > 0 && (
                    <div className="chapter-support-urls">
                      <h3>Support URLs</h3>
                      <ListGroup>
                        {selectedChapter.support_urls.map((url) => (
                          <ListGroupItem key={url.id_knowledge_support_url}>
                            <Card>
                              <Card.Body>
                                <Card.Title>{url.title}</Card.Title>
                                <Card.Text>{url.notes}</Card.Text>
                                <Card.Link
                                  href={url.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {url.url}
                                </Card.Link>
                              </Card.Body>
                            </Card>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <ScrollToTop />

          {/* Share Buttons */}
          <div className="share-buttons">
            <h3>Share this project</h3>
            <FacebookShareButton url={shareUrl} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} title={title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} title={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          {/* GitHub Link */}
          {url_git && (
            <div className="project-git-link">
              <a href={url_git} target="_blank" rel="noopener noreferrer">
                View on GitHub
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
