import { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import iconLinkedIn from "../assets/img/nav-icon1.svg";
import iconFacebook from "../assets/img/nav-icon2.svg";
import iconInstagram from "../assets/img/nav-icon3.svg";
import iconYouTube from "../assets/img/nav-icon3.svg";
import tusmodelosLogo from "../assets/img/TusModelos-logo.png";
import DataContext from "../contexts/DataContext";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { data } = useContext(DataContext);
  const infoSection = data?.general_info || {};
  const socialMediaLinks = data?.social_media || {};

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={tusmodelosLogo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {socialMediaLinks.social_media_linkedin && (
                <a
                  href={socialMediaLinks.social_media_linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconLinkedIn} alt="LinkedIn" />
                </a>
              )}
              {socialMediaLinks.social_media_instagram && (
                <a
                  href={socialMediaLinks.social_media_instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconInstagram} alt="Instagram" />
                </a>
              )}
              {socialMediaLinks.social_media_facebook && (
                <a
                  href={socialMediaLinks.social_media_facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconFacebook} alt="Facebook" />
                </a>
              )}
              {socialMediaLinks.social_media_youtube && (
                <a
                  href={socialMediaLinks.social_media_youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconYouTube} alt="YouTube" />
                </a>
              )}
            </div>
            <p>
              Portfolio System made with React by{" "}
              <a
                href="https://www.linkedin.com/in/gustavo-reguerin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gustavo Reguerin
              </a>
              .
            </p>
            <p>Copyright {currentYear}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
