import React, { useContext } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { Link, useParams, useNavigate } from "react-router-dom";
import colorSharp2 from "../assets/img/color-sharp2-01.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import DataContext from "../contexts/DataContext";

export const Projects = () => {
  const { data } = useContext(DataContext);
  const { userId } = useParams();
  const navigate = useNavigate(); // Use navigate for programmatic navigation
  const projectsData = data?.projects;
  const projectsIntro = data?.introductions?.introduction_projects;

  // Get project category keys and data
  const projectCategories = Object.keys(projectsData || {});

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const handleProjectClick = (projectId, projectTitle) => {
    const slug = createSlug(projectTitle);
    navigate(`/${userId}/projects/${projectId}/${slug}`);
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p className="project-intro">
                    {projectsIntro ||
                      "Below are detailed a few of my top projects:"}
                  </p>
                  <Tab.Container
                    id="projects-tabs"
                    defaultActiveKey={projectCategories[0]}
                  >
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      {projectCategories.map((category) => (
                        <Nav.Item key={category}>
                          <Nav.Link eventKey={category}>{category}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      {projectCategories.map((category) => (
                        <Tab.Pane eventKey={category} key={category}>
                          <Row>
                            {projectsData[category].projects.map(
                              (project, index) => (
                                <Col key={index} size={12} sm={6} md={4}>
                                  {/* <Link
                                    to={`/${userId}/projects/${
                                      project.id_knowledge
                                    }/${createSlug(project.title)}`}
                                  > */}
                                  <div
                                    onClick={() =>
                                      handleProjectClick(
                                        project.id_knowledge,
                                        project.title
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <ProjectCard
                                      title={project.title || "Project Title"}
                                      description={
                                        project.resume || "Project Description"
                                      }
                                      imgUrl={
                                        project.image || "default-image-url.png"
                                      }
                                    />
                                  </div>
                                  {/* </Link> */}
                                </Col>
                              )
                            )}
                          </Row>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
        <img
          className="background-image-right"
          src={colorSharp2}
          alt="Decorative Background"
        ></img>
      </Container>
    </section>
  );
};
