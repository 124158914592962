import { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import iconLinkedIn from "../assets/img/nav-icon1.svg";
import iconFacebook from "../assets/img/nav-icon2.svg";
import iconInstagram from "../assets/img/nav-icon3.svg";
import iconYouTube from "../assets/img/nav-icon3.svg";

import { HashLink } from "react-router-hash-link";
import { useLocation, Link } from "react-router-dom";
import DataContext from "../contexts/DataContext";
import classNames from "classnames";

export const NavBar = ({ userId }) => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const { data } = useContext(DataContext);
  const infoSection = data?.general_info || {};
  const socialMediaLinks = data?.social_media || {};

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  // Determine if we are on a project details page
  const isProjectDetails = location.pathname.includes("/projects/");

  const userPath = `/${userId}`;

  return (
    // <Router>
    // <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
    <Navbar
      expand="md"
      className={classNames({
        navbar: true,
        scrolled: scrolled,
        toggled: expanded,
      })}
      onToggle={() => setExpanded((prev) => !prev)} // Maneja el estado de expanded al toggle
    >
      <Container>
        {/* <Navbar.Brand href="/"> */}
        <Navbar.Brand as={Link} to={userPath}>
          {/* <img src={logo} alt="Logo" /> */}
          <span className="title-Gotham-Ultra">{infoSection.my_title}</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              // as={Link}
              as={HashLink}
              to={isProjectDetails ? `${userPath}#home` : `#home`}
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              // as={Link}
              as={HashLink}
              to={isProjectDetails ? `${userPath}#skills` : `#skills`}
              className={
                activeLink === "skills" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("skills")}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              // as={Link}
              as={HashLink}
              to={isProjectDetails ? `${userPath}#projects` : `#projects`}
              className={
                activeLink === "projects" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("projects")}
            >
              Projects
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              {socialMediaLinks.social_media_linkedin && (
                <a
                  href={socialMediaLinks.social_media_linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconLinkedIn} alt="LinkedIn" />
                </a>
              )}
              {socialMediaLinks.social_media_instagram && (
                <a
                  href={socialMediaLinks.social_media_instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconInstagram} alt="Instagram" />
                </a>
              )}
              {socialMediaLinks.social_media_facebook && (
                <a
                  href={socialMediaLinks.social_media_facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconFacebook} alt="Facebook" />
                </a>
              )}
              {socialMediaLinks.social_media_youtube && (
                <a
                  href={socialMediaLinks.social_media_youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iconYouTube} alt="YouTube" />
                </a>
              )}
            </div>
            <HashLink
              to={isProjectDetails ? `${userPath}#connect` : `#connect`}
            >
              <button className="vvd">
                <span>Let’s Connect</span>
              </button>
            </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    // </Router>
  );
};
