// components/ScrollToTop.js
import React, { useState, useEffect } from "react";

const ScrollToTop = () => {
  const [showToTop, setShowToTop] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setShowToTop(scrollTop > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <a href="#top" className={`to-top ${showToTop ? "visible" : ""}`}>
      To Top
    </a>
  );
};

export default ScrollToTop;
