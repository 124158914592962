import axios from "axios";

const API_USER_URL = process.env.REACT_APP_API_USER_URL;
const API_CONTENT_URL = process.env.REACT_APP_API_CONTENT_URL;
const API_PROJECT_URL = process.env.REACT_APP_API_PROJECT_URL;

export const fetchApiKey = async (userId) => {
  try {
    // console.log("fetchApiKey called with userId:", userId);
    const response = await axios.get(`${API_USER_URL}?user=${userId}`);
    // console.log("fetchApiKey response:", response);
    return response.data.api_key;
  } catch (error) {
    // console.error("Error fetching API key", error);
    throw error;
  }
};

export const fetchUserData = async (apiKey) => {
  try {
    // console.log("fetchUserData called with apiKey:", apiKey);
    const response = await axios.get(`${API_CONTENT_URL}?key=${apiKey}`);
    // console.log("fetchUserData response:", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};

export const fetchProjectDetails = async (projectId, apiKey) => {
  try {
    // console.log(
    //   "fetchProjectDetails called with projectId and apiKey:",
    //   projectId,
    //   apiKey
    // );
    const response = await axios.get(
      `${API_PROJECT_URL}?key=${apiKey}&idk=${projectId}`
    );
    // console.log("Project details response:", response);
    return response.data.projects[0];
  } catch (error) {
    console.error("Error fetching project details", error);
    throw error;
  }
};

// import axios from "axios";

// const API_USER_URL = process.env.REACT_APP_USER_URL;
// const API_CONTENT_URL = process.env.REACT_APP_API_CONTENT_URL;
// const API_PROJECT_URL = process.env.REACT_APP_API_PROJECT_URL;

// export const fetchApiKey = async (userId) => {
//   try {
//     const response = await axios.get(`${API_USER_URL}?user=${userId}`);
//     return response.data.api_key;
//   } catch (error) {
//     console.error("Error fetching API key", error);
//     throw error;
//   }
// };

// export const fetchUserData = async (apiKey) => {
//   try {
//     const response = await axios.get(`${API_CONTENT_URL}?key=${apiKey}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data", error);
//     throw error;
//   }
// };

// export const fetchProjectDetails = async (projectId, apiKey) => {
//   try {
//     const response = await axios.get(
//       `${API_PROJECT_URL}?key=${apiKey}&idk=${projectId}`
//     );
//     console.log("Project details response:", response);
//     return response.data.projects[0];
//   } catch (error) {
//     console.error("Error fetching project details", error);
//     throw error;
//   }
// };
