import { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/space_06.gif";
import image_bg from "../assets/img/banner-bg-1.jpg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DataContext from "../contexts/DataContext";
import { Parallax } from "react-parallax";
const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;

export const Contact = ({ apiKey }) => {
  const formInitialDetails = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    key: apiKey,
  };
  const { data } = useContext(DataContext);
  const generalInfo = data?.general_info || {};
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const emailDestiny = generalInfo.email;

  useEffect(() => {
    if (apiKey) {
      setFormDetails((details) => ({ ...details, apiKey }));
    }
  }, [apiKey]);

  const onFormUpdate = (category, value) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [category]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [category]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    const { first_name, last_name, email, phone, message } = formDetails;
    if (!first_name) errors.first_name = "First name is required";
    if (!last_name) errors.last_name = "Last name is required";
    if (!email) errors.email = "Email is required";
    if (!phone) errors.phone = "Phone number is required";
    if (!message) errors.message = "Message is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setButtonText("Sending...");

    if (!validateForm()) {
      setButtonText("Send");
      setIsSubmitting(false);
      return;
    }

    // Serialize form details to query string
    const queryString = new URLSearchParams(formDetails).toString();
    const urlWithParams = `${CONTACT_URL}?${queryString}`;

    let response = await fetch(urlWithParams, {
      method: "GET",
    });

    if (response.ok) {
      let result = await response.json();
      setStatus({
        success: true,
        message: "Message sent successfully",
      });
      setIsSubmitted(true);
    } else {
      let error = await response.text();
      setStatus({
        success: false,
        message:
          "Something went wrong, please try again later. Error: " + error,
      });
    }

    setButtonText("Send");
    setIsSubmitting(false);
    setFormDetails(formInitialDetails);
  };

  return (
    <section id="connect">
      <Parallax bgImage={image_bg} strength={600}>
        <div className="contact">
          <Container>
            <Row className="align-items-center">
              <Col size={12} md={6}>
                <TrackVisibility>
                  {({ isVisible }) => (
                    <div
                      className={
                        isVisible ? "animate__animated animate__zoomIn" : ""
                      }
                    >
                      <img src={contactImg} alt="Contact Us" />
                    </div>
                  )}
                </TrackVisibility>
              </Col>
              <Col size={12} md={6}>
                <TrackVisibility>
                  {({ isVisible }) => (
                    <div
                      className={
                        isVisible ? "animate__animated animate__fadeIn" : ""
                      }
                    >
                      <h2>Get In Touch</h2>
                      {!isSubmitted ? (
                        <form
                          onSubmit={handleSubmit}
                          id="myForm"
                          name="contactForm"
                          method="post"
                        >
                          <Row>
                            <Col size={12} sm={6} className="px-1">
                              <label>First Name</label>
                              <input
                                type="text"
                                value={formDetails.first_name}
                                onChange={(e) =>
                                  onFormUpdate("first_name", e.target.value)
                                }
                              />
                              {formErrors.first_name && (
                                <p className="text-danger">
                                  {formErrors.first_name}
                                </p>
                              )}
                            </Col>
                            <Col size={12} sm={6} className="px-1">
                              <label>Last Name</label>
                              <input
                                type="text"
                                value={formDetails.last_name}
                                onChange={(e) =>
                                  onFormUpdate("last_name", e.target.value)
                                }
                              />
                              {formErrors.last_name && (
                                <p className="text-danger">
                                  {formErrors.last_name}
                                </p>
                              )}
                            </Col>
                            <Col size={12} sm={12} className="px-1">
                              <label>Email</label>
                              <input
                                type="email"
                                value={formDetails.email}
                                onChange={(e) =>
                                  onFormUpdate("email", e.target.value)
                                }
                              />
                              {formErrors.email && (
                                <p className="text-danger">
                                  {formErrors.email}
                                </p>
                              )}
                            </Col>
                            <Col size={12} sm={12} className="px-1">
                              <label>Phone</label>
                              <PhoneInput
                                international
                                defaultCountry="US"
                                value={formDetails.phone}
                                onChange={(value) =>
                                  onFormUpdate("phone", value)
                                }
                              />
                              {formErrors.phone && (
                                <p className="text-danger">
                                  {formErrors.phone}
                                </p>
                              )}
                            </Col>
                            <Col size={12} sm={12} className="px-1">
                              <label>Message</label>
                              <textarea
                                rows="5"
                                value={formDetails.message}
                                onChange={(e) =>
                                  onFormUpdate("message", e.target.value)
                                }
                              ></textarea>
                              {formErrors.message && (
                                <p className="text-danger">
                                  {formErrors.message}
                                </p>
                              )}
                            </Col>
                            <Col size={12} className="px-1">
                              <button type="submit" disabled={isSubmitting}>
                                {buttonText}
                              </button>
                            </Col>
                            {status.message && (
                              <Col>
                                <p
                                  className={
                                    status.success
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {status.message}
                                </p>
                              </Col>
                            )}
                          </Row>
                        </form>
                      ) : (
                        <div className="thank-you-message">
                          <h3>Thank you for getting in touch!</h3>
                          <p>
                            I have received your message, I will contact you
                            shortly.
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </TrackVisibility>
              </Col>
            </Row>
          </Container>
        </div>
      </Parallax>
    </section>
  );
};
export default Contact;
