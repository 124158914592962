import React, { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchUserData,
  fetchProjectDetails,
  fetchApiKey,
} from "../services/api";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { userId } = useParams();
  const [data, setData] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(true); //useState(false);
  const [errorUser, setErrorUser] = useState(true);

  useEffect(() => {
    const getApiKey = async () => {
      // setLoading(true);
      try {
        const key = await fetchApiKey(userId);
        // console.log("User:", userId);
        // console.log("User key:", key);
        setApiKey(key);
        setLoading(false); // Stop loading after getting the API key.
        setErrorUser(false);
      } catch (error) {
        console.error("Error fetching API key:::", error);
        setErrorUser(true);
        setLoading(false); // Ensure loading is stopped on error.
      }
      // finally {
      //   setLoading(false);
      // }
    };

    if (userId) {
      getApiKey();
    }
  }, [userId, errorUser]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        if (apiKey) {
          const result = await fetchUserData(apiKey);
          setData(result);
          setLoading(false); // Stop loading when data is fetched.
        }
      } catch (error) {
        console.error("Error in DataProvider:", error);
        setLoading(false); // Ensure loading is stopped on error.
      }
      // finally {
      //   setLoading(false);
      // }
    };

    if (apiKey) {
      getData();
    }
  }, [apiKey]);

  const getProjectDetails = async (projectId) => {
    if (!projectId || !apiKey) return;
    try {
      // setLoading(true);
      const result = await fetchProjectDetails(projectId, apiKey);
      setProjectDetails(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching project details:", error);
      setLoading(false);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  return (
    <DataContext.Provider
      value={{
        data,
        projectDetails,
        apiKey,
        getProjectDetails,
        loading,
        errorUser,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext };
export default DataContext;
